import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NewGameService} from "../../../services/new-game.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {IGameAssetSettingsForm} from "../../../interfaces/new-game/forms/IGameAssetSettingsForm";
import {MatError, MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatButton, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {MatToolbar} from "@angular/material/toolbar";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {NgxColorsModule} from "ngx-colors";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {GameThemeType} from "../../../enum/GameThemeType";
import {IUploadImageToAzure} from "../../../interfaces/IUploadImageToAzure";
import {ActiveUserService} from "../../../services/active-user.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {AppConfigService} from "../../../services/app-config.service";
import {CreateImageState} from "../../../enum/CreateImageState";

@Component({
  selector: 'app-game-asset-settings-form',
  standalone: true,
  templateUrl: './game-asset-settings-form.component.html',
  imports: [
    MatLabel,
    ReactiveFormsModule,
    MatMiniFabButton,
    MatToolbar,
    MatIcon,
    MatHint,
    MatError,
    MatIconButton,
    MatFormField,
    MatInput,
    NgIf,
    NgxColorsModule,
    MatOption,
    MatSelect,
    MatButton,
    NgOptimizedImage
  ],
  styleUrl: './game-asset-settings-form.component.scss'
})
export class GameAssetSettingsFormComponent implements OnInit {

  protected readonly GameThemeType = GameThemeType;
  protected readonly CreateImageState = CreateImageState;

  private newGameService: NewGameService = inject(NewGameService);
  private activeUserService: ActiveUserService = inject(ActiveUserService);
  private appConfigService: AppConfigService = inject(AppConfigService);
  private snackbarService: SnackbarService = inject(SnackbarService);

  public toolbarColor = '#222222';
  public iconColor = '#ffffff';

  public qrCodeImageState: CreateImageState = CreateImageState.NoImageSaved;
  public gameLogoImageState: CreateImageState = CreateImageState.NoImageSaved;

  qrCodeImage: File | null = null;
  qrCodeImageUrl: string | ArrayBuffer | null = null;

  gameLogoImage: File | null = null;
  gameLogoUrl: string = '';

  private urlRegex: RegExp = /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9][a-zA-Z0-9_-]+\.[a-zA-Z]{2,9}(\/.*)?$/;

  @Input() newGameAssetsSettingsForm: FormGroup<IGameAssetSettingsForm> = new FormGroup<IGameAssetSettingsForm>({
    qrCodeImage: new FormControl<string>('', Validators.pattern(this.urlRegex)),
    primaryGameColor: new FormControl<string>('', Validators.required),
    gameThemeTypeId: new FormControl<number>(0, Validators.required),
  });

  @Output() newGameAssetsSettingsFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  ngOnInit(): void {
    this.populateGameDrawFromState();
    this.checkForExistingGameAssets();
  }

  private checkForExistingGameAssets(): void {
    const cdnUrl = `${this.appConfigService.cdnRoot()}/assets/${this.newGameService.newGameSubdomain()}`;
    const qrCodeUrl = cdnUrl + '/qrcode.png';
    this.gameLogoUrl = cdnUrl + '/game-logo.svg';

    if (this.gameLogoUrl) {
      this.gameLogoImageState = CreateImageState.ViewSavedImage;
    }

    const qrCodeValid = this.validateImage(qrCodeUrl);
    const combined = Promise.all([qrCodeValid]);
    combined.then(([validQrCode]) => {
      if (validQrCode) {
        this.qrCodeImageUrl = qrCodeUrl;
        this.qrCodeImageState = CreateImageState.ViewSavedImage;
      }

    })
  }

  validateImage(srcUrlP: string): Promise<boolean> {
    // if the game does not have a provided qr code, then do not show it
    return new Promise<boolean>((resolve) => {
      const img = new Image();
      img.src = srcUrlP;
      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  }

  onThemeTypeChange(valueP: GameThemeType) {
    switch (valueP) {
      case GameThemeType.Light:
        this.toolbarColor = '#ffffff';
        this.iconColor = '#222222';
        break;
      case GameThemeType.Dark:
        this.toolbarColor = '#222222';
        this.iconColor = '#ffffff';
        break;
      default:
        this.toolbarColor = '#222222';
        this.iconColor = '#ffffff';
        break;
    }
  }

  onFormChange() {
    this.newGameAssetsSettingsFormChange.emit(this.newGameAssetsSettingsForm)
  }

  onQrCodeFileSelected(event: any) {
    this.qrCodeImage = event.target.files[0];
    if (this.qrCodeImage) {

      const reader = new FileReader();
      reader.readAsDataURL(this.qrCodeImage);
      reader.onload = () => {
        this.qrCodeImageUrl = reader.result;
      };
    }
  }

  onGameLogoFileSelected(event: any) {
    this.gameLogoImage = event.target.files[0];
    if (this.gameLogoImage) {

      const reader = new FileReader();
      reader.readAsDataURL(this.gameLogoImage);
      reader.onload = () => {
        this.gameLogoUrl = reader.result as string;
      };
    }
  }

  editGameLogo() {
    this.gameLogoUrl = '';
    this.gameLogoImageState = CreateImageState.NoImageSaved;
  }

  editQRCode() {
    this.qrCodeImageUrl = '';
    this.qrCodeImageState = CreateImageState.NoImageSaved;
  }

  saveQRCode() {
    const formData = new FormData();

    if (this.qrCodeImage) {
      formData.append('file', this.qrCodeImage, 'qrcode.png');

      const new_logo_request: IUploadImageToAzure = {
        gameId: this.newGameService.newGameId()!,
        image: formData,
        adminId: this.activeUserService.activeUser().Id
      };

      this.newGameService.uploadGameImage(new_logo_request).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.snackbarService.openSuccessfulSnackBar('Successfully uploaded Game QR Code.');
            this.checkForExistingGameAssets();
          }
        },
        error: () => {
          this.snackbarService.openErrorSnackBar('Failed to upload Game QR Code.');
        }
      });
    }
  }

  saveGameLogo() {
    const formData = new FormData();

    if (this.gameLogoImage) {
      formData.append('file', this.gameLogoImage, 'game-logo.svg');

      const new_logo_request: IUploadImageToAzure = {
        gameId: this.newGameService.newGameId()!,
        image: formData,
        adminId: this.activeUserService.activeUser().Id
      };

      this.newGameService.uploadGameImage(new_logo_request).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.snackbarService.openSuccessfulSnackBar('Successfully uploaded Game Logo.');
            this.checkForExistingGameAssets();
          }
        },
        error: err => {
          this.snackbarService.openErrorSnackBar('Failed to upload Game Logo.');
        }
      });

    }
  }

  private populateGameDrawFromState() {
    const newGameDrawFromState = this.newGameService.getNewGameRequest()?.newGameAssets;
    if (newGameDrawFromState) {

      this.newGameAssetsSettingsForm.patchValue({
          gameThemeTypeId: newGameDrawFromState.gameThemeTypeId,
          primaryGameColor: newGameDrawFromState.primaryGameColor
        }
      )
    }

    this.newGameAssetsSettingsFormChange.emit(this.newGameAssetsSettingsForm)
  }

}
