<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Edit Game More Information</span>

  <ng-container dialog-content>
    <form>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>More Information</mat-label>
        <textarea matInput data-testid="game-more-information-input" [formControl]="moreInformationControl"></textarea>
        <mat-error *ngIf="(moreInformationControl.dirty || moreInformationControl.touched) && moreInformationControl.errors">
          More Information cannot be empty
        </mat-error>
      </mat-form-field>
    </form>

    <div class="markdown-preview">
      <markdown [data]="formatMarkdown(moreInformationControl.value!)"></markdown>
    </div>
  </ng-container>

  <ng-container actions>
    <button mat-button data-testid="game-more-information-submit-button" (click)="submit()">Submit</button>
  </ng-container>
</app-dialog-base>
