import {Component, Input, OnInit} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {PlayersService} from "../../../services/players.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {GameService} from "../../../services/game.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {PlayerInfoTableDefs} from "../../player-game-info/player-info-table-defs";
import {IPlayerUnsubscribeResult} from "../../../interfaces/player/IPlayerUnsubscribeResult";
import {ILastFiveFailedEmailsQueryResult} from "../../../interfaces/player/ILastFiveFailedEmailsQueryResult";
import {map, Observable} from "rxjs";
import {GridApi, GridReadyEvent, RowClickedEvent} from "ag-grid-community";
import {ISendGridMessageData} from "../../../interfaces/ISendGridMessageData";
import {IRemoveUnsubscribesForPlayer} from "../../../interfaces/player/IRemoveUnsubscribesForPlayer";
import {MatList, MatListItem} from "@angular/material/list";
import {MatIcon} from "@angular/material/icon";
import {AgGridAngular} from "ag-grid-angular";
import {AsyncPipe, NgClass} from "@angular/common";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton} from "@angular/material/button";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from "@angular/material/datepicker";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ISendGridEventPagedResponse} from "../../../interfaces/ISendGridEventPagedResponse";
import {IPlayerPerGameQueryResult} from "../../../interfaces/player/IPlayerPerGameQueryResult";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {sendGridEventTypes} from "../../player-game-info/send-grid-events-tab/send-grid-event-types";
import {ISendGridEventFilter} from "../../../interfaces/ISendGridEventFilter";
import {ISendGridEvent} from "../../../interfaces/ISendGridEvent";
import {SendGridService} from "../../../services/send-grid.service";
import {DateService} from "../../../services/date.service";
import {SendGridTable} from "../../../table-definitions/sendgrid-table";
import {
  SendGridEventMetadataComponent
} from "../../dialogs/send-grid-event-metadata/send-grid-event-metadata.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-manage-player-send-grid-info',
  standalone: true,
  imports: [
    MatListItem,
    MatList,
    MatIcon,
    AgGridAngular,
    AsyncPipe,
    MatProgressSpinner,
    MatButton,
    HasPermissionDirective,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatEndDate,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatStartDate,
    MatSuffix,
    ReactiveFormsModule,
    NgClass
  ],
  templateUrl: './manage-player-send-grid-info.component.html',
  styleUrl: './manage-player-send-grid-info.component.css'
})
export class ManagePlayerSendGridInfoComponent implements OnInit{
  @Input() public playerEmail: string = "";

  public pagedEventResponse: ISendGridEventPagedResponse | null = null;
  public gridApi!: GridApi;
  public columnDefs = this.sendGridTable.sendGridTableDefs;
  public chosenPlayer: IPlayerPerGameQueryResult | undefined = this.playerService.getActivePlayer();
  public activeGame: IGameQueryResult | undefined = this.gameService.activeGame();

  public eventTypes = sendGridEventTypes;
  public emailTemplateTypes = [{value: 1, name: "Failed Subscription Payment"},
    {value: 2, name: 'Game Instance Draw Winner Notification'}, {value: 3, name: 'Organization Game Winner Notification'},
    {value: 4, name: 'Player Account Suspension Inquiry'}, {value: 5, name: 'Player Account Suspension Notification'},
    {value: 6, name: 'Player Number Transaction Receipt'}, {value: 7, name: 'Player Password Recovery'},
    {value: 8, name: 'Register Player'}, {value: 9, name: 'Register Game Admin'}, {value: 10, name: 'Register Causable and Org Admins'},
    {value: 11, name: 'Admin Password Recovery'}, {value: 12, name: 'Admin Permission Added Successfully'},
    {value: 13, name: 'Player Email Update'}, {value: 14, name: 'Admin Email Update'}, {value: 15, name: 'Pending Draw Notification'},
    {value: 16, name: 'Draw Results Notification'}, {value: 17, name: 'Payment Dispute Notification'},
    {value: 18, name: 'Deactivated Charity Notification'}, {value: 19, name: 'Email Blast'},
    {value: 20, name: 'Admin Charity Added'}, {value: 21, name: 'Register Charity Admin'}, {value: 22, name: 'Winners Pending Decisions'}];

  public templateIdFormControl = new FormControl<number | null>(null);
  public eventControl = new FormControl<string | null>("");
  public emailControl = new FormControl<string | null>("");
  public fromDateFormControl: FormControl = new FormControl(this.dateService.twoWeeksAgo());
  public toDateFormControl: FormControl = new FormControl(this.dateService.tomorrow());

  public sendGridEventFilterFormGroup = new FormGroup({
    templateId: this.templateIdFormControl,
    event: this.eventControl,
    email: this.emailControl,
    fromDate: this.fromDateFormControl,
    toDate: this.toDateFormControl
  });

  defaultFilter: ISendGridEventFilter = {
    fromDate: this.dateService.twoWeeksAgo(),
    toDate: this.dateService.tomorrow(),
    pageSize: 15,
    pageNumber: 1,
    templateId: undefined,
    playerId: this.chosenPlayer?.Id!,
    gameId: this.activeGame?.Id!,
    email: '',
    event: ''
  };

  public events$: Observable<ISendGridEvent[]> = this.sendGridService.events$.pipe(map((response) => {
    this.pagedEventResponse = response;
    return response.Data;
  }))

  protected fromNumber: number = 0;
  protected toNumber: number = 0;
  protected totalRecords: number = 0;
  protected pageNumber: number = 0;
  protected totalPages: number = 0;
  public playerSuppressions: IPlayerUnsubscribeResult | null = null;
  public lastFiveFailedEmails$: Observable<ILastFiveFailedEmailsQueryResult> | null = null;
  public sendGridMessageDataGridApi!: GridApi<ISendGridMessageData>;
  public sendGridColDefs = this.colDefs.sendGridMessageDataColDefs;
  public uiState = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public permissionTypes = PermissionTypes;

  constructor(private playerService: PlayersService,
              private snackBarService: SnackbarService,
              private gameService: GameService,
              private activeUserService: ActiveUserService,
              private errorHandlingService: ErrorHandlingService,
              private colDefs: PlayerInfoTableDefs,
              private sendGridService: SendGridService,
              private dateService: DateService,
              private sendGridTable: SendGridTable,
              private matDialog: MatDialog) {
  }

  ngOnInit() {
    this.sendGridService.getEvents(this.defaultFilter);
    this.fetchData();
  }



  fetchData() {
    this.uiState = UIStateEnum.ShowLoading;
    this.playerService.getUnsubscribesForPlayer(this.playerEmail).subscribe({
      next: (result) => {
        this.uiState = UIStateEnum.ShowData;
        this.playerSuppressions = result;
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage("Failed to fetch unsubscribe info for player");
      }
    });
    this.lastFiveFailedEmails$ = this.playerService.getLastFiveFailedEmailsForPlayer(this.playerEmail);
  }

  removeUnsubscribes() {
    let command: IRemoveUnsubscribesForPlayer = {
      PlayerEmail: this.playerEmail,
      GameId: this.gameService.activeGame().Id,
      AdminId: this.activeUserService.activeUser().Id
    };

    this.uiState = UIStateEnum.ShowLoading;

    this.playerService.deleteUnsubscribesForPlayer(command).subscribe({
      next: () => {
        this.snackBarService.openSuccessfulSnackBar("Successfully removed unsubscribes and spam reports");
        this.uiState = UIStateEnum.ShowData;
        this.fetchData();
      },
      error: err => {
        this.snackBarService.openErrorSnackBar("Failed to remove unsubscribes and spam reports");
        this.uiState = UIStateEnum.ShowData;
      }
    });
  }

  onSendGridMessageDataGridReady(params: GridReadyEvent<ISendGridMessageData>) {
    this.sendGridMessageDataGridApi = params.api;
    this.sendGridMessageDataGridApi.sizeColumnsToFit({
      defaultMinWidth: 100
    });
  }

  onSendGridEventGridReady(paramsP: GridReadyEvent<ISendGridEvent>) {
    this.gridApi = paramsP.api;
    this.gridApi.sizeColumnsToFit({
      defaultMinWidth: 90
    });

    this.updatePaginator();
  }

  public onFormSubmit() {
    this.getPaginatedResponse(1);
  }

  public onResetClick() {
    this.templateIdFormControl.setValue(null);
    this.eventControl.setValue("");
    this.emailControl.setValue("");
    this.fromDateFormControl.setValue(this.dateService.twoWeeksAgo());
    this.toDateFormControl.setValue(this.dateService.tomorrow());

    this.getPaginatedResponse(1);
  }

  getPaginatedResponse(pageNumberP: number) {
    this.sendGridService.getEvents({
      playerId: this.chosenPlayer?.Id!,
      templateId: this.templateIdFormControl.value!,
      gameId: this.activeGame?.Id!,
      event: this.eventControl.value!,
      email: this.emailControl.value!,
      pageNumber: pageNumberP,
      pageSize: 15,
      fromDate: new Date(this.fromDateFormControl.value!),
      toDate: new Date(this.toDateFormControl.value!),
    }).subscribe({
      next: () => {
        this.updatePaginator()
      },
      error: err => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  onBtFirst() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(1);
  }

  onBtLast() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pagedEventResponse?.TotalPages!);
  }

  onBtNext() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber + 1);
  }

  onBtPrevious() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber - 1);
  }

  onRowClicked(eventP: RowClickedEvent<ISendGridEvent>) {
    this.matDialog.open(SendGridEventMetadataComponent, {
      data: eventP.data
    });
  }

  updatePaginator() {
    this.pageNumber = this.pagedEventResponse!.PageNumber;
    this.totalPages = this.pagedEventResponse!.TotalPages;
    this.totalRecords = this.pagedEventResponse!.TotalRecords;
    let toNumber = this.pageNumber * this.pagedEventResponse!.PageSize;
    if (toNumber > this.totalRecords) {
      this.toNumber = this.totalRecords;
      this.fromNumber = (toNumber - this.pagedEventResponse!.PageSize) + 1;
    } else {
      this.toNumber = toNumber;
      this.fromNumber = (this.toNumber - this.pagedEventResponse!.PageSize) + 1;
    }
  }
}
