import { Injectable } from '@angular/core';
import {APIService} from "./api.service";
import {ITestEmails} from "../interfaces/ITestEmails";

@Injectable({
  providedIn: 'root'
})
export class EmailTestingService {

  constructor(private apiService: APIService) {

  }

  public sendTestEmails(testEmailsRequest: ITestEmails){
    return this.apiService.MakePostRequest<any>('admin-user/send-test-emails', testEmailsRequest);
  }
}
