import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ISendGridEvent} from "../../../interfaces/ISendGridEvent";
import {SendGridService} from "../../../services/send-grid.service";
import {GameService} from "../../../services/game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {FormatterComponent} from "../../json-formatter/json-formatter.component";
import {UIStateEnum} from "../../../enum/UIStateEnum";

@Component({
  selector: 'app-send-grid-event-metadata',
  standalone: true,
  imports: [
    DialogBaseComponent,
    FormatterComponent,
  ],
  templateUrl: './send-grid-event-metadata.component.html',
  styleUrl: './send-grid-event-metadata.component.css'
})
export class SendGridEventMetadataComponent implements OnInit{
  public metadata: any;
  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public event: ISendGridEvent,
              private sendGridService: SendGridService,
              private gameService: GameService,
              private errorHandlingService: ErrorHandlingService) {
  }

  ngOnInit() {
    this.getMetadata();
  }

  getMetadata(){
    this.uiState = UIStateEnum.ShowLoading;

    let game_id = this.gameService.activeGame().Id;
    this.sendGridService.getEventMetadata(this.event.Id, game_id).subscribe({
      next: response => {
        this.uiState = UIStateEnum.ShowData;
        this.metadata = response
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err);
      }
    })
  }
}
