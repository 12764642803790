<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Edit Game Description</span>

  <ng-container dialog-content>
  <form>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Description</mat-label>
      <textarea matInput data-testid="game-description-input" [formControl]="descriptionControl"></textarea>
      <mat-error *ngIf="(descriptionControl.dirty || descriptionControl.touched) && descriptionControl.errors">
        Description must not be empty
      </mat-error>
    </mat-form-field>
  </form>
  </ng-container>

  <ng-container actions>
    <button mat-button class="btn-success" data-testid="game-description-submit-button" (click)="submit()">Submit</button>
  </ng-container>
</app-dialog-base>
