import {Component, inject, OnInit} from '@angular/core';
import {AgGridAngular} from "ag-grid-angular";
import {
  ComponentLevelErrorMessageComponent
} from "../../display-errors/component-level-error-message/component-level-error-message.component";
import {FormsModule} from "@angular/forms";
import {LoadingCardContentComponent} from "../../../shared/loading-card-content/loading-card-content.component";
import {MatButton} from "@angular/material/button";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {IGameCharitySupporterBreakdown} from "../../../interfaces/reporting/IGameCharitySupporterBreakdown";
import {ReportingService} from "../../../services/reporting.service";
import {GameService} from "../../../services/game.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-game-charity-supporter-breakdown',
  standalone: true,
  imports: [
    AgGridAngular,
    ComponentLevelErrorMessageComponent,
    FormsModule,
    LoadingCardContentComponent,
    MatButton
  ],
  templateUrl: './game-charity-supporter-breakdown.component.html',
  styleUrl: './game-charity-supporter-breakdown.component.css',
  providers: [DatePipe]
})
export class GameCharitySupporterBreakdownComponent implements OnInit {

  private reportingService: ReportingService = inject(ReportingService);
  private gameService: GameService = inject(GameService);
  private datePipe: DatePipe = inject(DatePipe);

  public uiState = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  public errorMessage: string = '';
  public charityBreakdown: IGameCharitySupporterBreakdown[] = [];

  public gridApi!: GridApi<IGameCharitySupporterBreakdown>;

  public reportDefs: ColDef[] = [
    {
      headerName: 'Charity Name',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'CharityName'
    },
    {
      headerName: 'Active Supporters',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'ActiveSupporters'
    },
    {
      headerName: 'Inactive Supporters',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'InactiveSupporters'
    },
    {
      headerName: 'Autoplay Supporters',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'AutoplaySupporters'
    }
  ];

  ngOnInit(): void {
    this.reportingService.fetchGameCharitySupporterBreakdown(this.gameService.activeGame().Id).subscribe({
      next: (res) => {
        this.charityBreakdown = res;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (error) => {
        this.errorMessage = 'Failed to fetch charity breakdown';
      }
    })
  }

  onGridReady(params: GridReadyEvent<IGameCharitySupporterBreakdown>) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }

  onBtnExport() {
    let formattedDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd')!;
    this.gridApi.exportDataAsCsv({
      fileName: `charity-supporters-breakdown-${formattedDate}`
    });
  }
}
