import { Component } from '@angular/core';
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatDialogRef} from "@angular/material/dialog";
import {GameService} from "../../../services/game.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {IEditGameMoreInformation} from "../../../interfaces/IEditGameMoreInformation";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {MarkdownComponent} from "ngx-markdown";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-edit-game-more-information',
  standalone: true,
  imports: [
    DialogBaseComponent,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    ReactiveFormsModule,
    MatButton,
    MarkdownComponent,
    NgIf
  ],
  templateUrl: './edit-game-more-information.component.html',
  styleUrl: './edit-game-more-information.component.css'
})
export class EditGameMoreInformationComponent {

  public uiState = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  public moreInformationControl = new FormControl<string | null>(null, Validators.required);

  constructor(private dialogRef: MatDialogRef<EditGameMoreInformationComponent>,
              private gameService: GameService,
              private activeUserService: ActiveUserService,
              private snackBarService: SnackbarService) {
    this.moreInformationControl.setValue(gameService.activeGame().GameMoreInformation);
  }

  submit() {
    if (this.moreInformationControl.errors) {
      this.moreInformationControl.markAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let command: IEditGameMoreInformation = {
      AdminId: this.activeUserService.activeUser().Id,
      GameId: this.gameService.activeGame().Id,
      MoreInformation: this.moreInformationControl.value!,
    };

    this.gameService.updateGameMoreInformation(command).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openSuccessfulSnackBar("Successfully updated game more information!");
        this.dialogRef.close();
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.snackBarService.openErrorSnackBar("Failed to update game more information!");
      }
    });
  }

  formatMarkdown(textP: string) {
    if (textP == undefined) {
      return "";
    }
    return textP.replace(/\n/, "<br>");
  }
}
