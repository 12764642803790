import {Component} from '@angular/core';
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {AgGridAngular} from "ag-grid-angular";
import {MatIcon} from "@angular/material/icon";
import {SendGridService} from "../../services/send-grid.service";
import {AsyncPipe, NgClass} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {DateService} from "../../services/date.service";
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from "@angular/material/datepicker";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {ISendGridEventFilter} from "../../interfaces/ISendGridEventFilter";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {GridApi, GridReadyEvent} from "ag-grid-community";
import {ISendGridEvent} from "../../interfaces/ISendGridEvent";
import {map, Observable} from "rxjs";
import {SendGridTable} from "../../table-definitions/sendgrid-table";
import {MatOption, MatSelect} from "@angular/material/select";
import {ISendGridEventPagedResponse} from "../../interfaces/ISendGridEventPagedResponse";
import {GameService} from "../../services/game.service";
import {ActiveUserService} from "../../services/active-user.service";

@Component({
  selector: 'app-send-grid-event-table',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatButton,
    AgGridAngular,
    MatIcon,
    NgClass,
    ReactiveFormsModule,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatEndDate,
    MatHint,
    MatLabel,
    MatStartDate,
    MatSuffix,
    AsyncPipe,
    MatSelect,
    MatOption
  ],
  templateUrl: './send-grid-event-table.component.html',
  styleUrl: './send-grid-event-table.component.css'
})
export class SendGridEventTableComponent {
  public pagedEventResponse: ISendGridEventPagedResponse | null = null;
  public gridApi!: GridApi;
  public columnDefs = this.sendGridTable.sendGridTableDefs;
  public eventTypes = ["Bounce", "Clicked", "Delivered", "Dropped", "Group Resubscribe", "Group Unsubscribe",
    "Opened", "Spam Report", "Unsubscribe"];
  public emailTemplateTypes = [{value: 1, name: "Failed Subscription Payment"},
    {value: 2, name: 'Game Instance Draw Winner Notification'}, {value: 3, name: 'Organization Game Winner Notification'},
    {value: 4, name: 'Player Account Suspension Inquiry'}, {value: 5, name: 'Player Account Suspension Notification'},
    {value: 6, name: 'Player Number Transaction Receipt'}, {value: 7, name: 'Player Password Recovery'},
    {value: 8, name: 'Register Player'}, {value: 9, name: 'Register Game Admin'}, {value: 10, name: 'Register Causable and Org Admins'},
    {value: 11, name: 'Admin Password Recovery'}, {value: 12, name: 'Admin Permission Added Successfully'},
    {value: 13, name: 'Player Email Update'}, {value: 14, name: 'Admin Email Update'}, {value: 15, name: 'Pending Draw Notification'},
    {value: 16, name: 'Draw Results Notification'}, {value: 17, name: 'Payment Dispute Notification'},
    {value: 18, name: 'Deactivated Charity Notification'}, {value: 19, name: 'Email Blast'},
    {value: 20, name: 'Admin Charity Added'}, {value: 21, name: 'Register Charity Admin'}, {value: 22, name: 'Winners Pending Decisions'}];
  public activeUserId = this.activeUserService.activeUser().Id;
  public games = this.gameService.getGamesForUser(this.activeUserId);

  public playerIdFormControl = new FormControl<string | null>("");
  public templateIdFormControl = new FormControl<number | null>(null);
  public gameIdFormControl = new FormControl<string | null>(null);
  public eventControl = new FormControl<string | null>("");
  public emailControl = new FormControl<string | null>("");
  public fromDateFormControl: FormControl = new FormControl(this.dateService.twoWeeksAgo());
  public toDateFormControl: FormControl = new FormControl(this.dateService.tomorrow());

  public sendGridEventFilterFormGroup = new FormGroup({
    playerId: this.playerIdFormControl,
    templateId: this.templateIdFormControl,
    gameId: this.gameIdFormControl,
    event: this.eventControl,
    email: this.emailControl,
    fromDate: this.fromDateFormControl,
    toDate: this.toDateFormControl
  });

  defaultFilter: ISendGridEventFilter = {
    fromDate: this.dateService.twoWeeksAgo(),
    toDate: this.dateService.tomorrow(),
    pageSize: 15,
    pageNumber: 1,
    templateId: undefined,
    playerId: '',
    gameId: '',
    email: '',
    event: ''
  };

  public events$: Observable<ISendGridEvent[]> = this.sendGridService.events$.pipe(map((response) => {
    this.pagedEventResponse = response;
    return response.Data;
  }))

  protected fromNumber: number = 0;
  protected toNumber: number = 0;
  protected totalRecords: number = 0;
  protected pageNumber: number = 0;
  protected totalPages: number = 0;

  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;

  constructor(private sendGridService: SendGridService,
              private dateService: DateService,
              private errorHandlingService: ErrorHandlingService,
              private sendGridTable: SendGridTable,
              private gameService: GameService,
              private activeUserService: ActiveUserService) {

  }

  ngOnInit() {
    this.sendGridService.getEvents(this.defaultFilter).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: err => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  onGridReady(paramsP: GridReadyEvent<ISendGridEvent>) {
    this.gridApi = paramsP.api;
    this.gridApi.sizeColumnsToFit({
      defaultMinWidth: 90
    });

    this.updatePaginator();
  }

  public onFormSubmit() {
    this.getPaginatedResponse(1);
  }

  public onResetClick() {
    this.playerIdFormControl.setValue("");
    this.templateIdFormControl.setValue(null);
    this.gameIdFormControl.setValue('');
    this.eventControl.setValue("");
    this.emailControl.setValue("");
    this.fromDateFormControl.setValue(this.dateService.twoWeeksAgo());
    this.toDateFormControl.setValue(this.dateService.tomorrow());

    this.getPaginatedResponse(1);
  }

  getPaginatedResponse(pageNumberP: number) {
    this.sendGridService.getEvents({
      playerId: this.playerIdFormControl.value!,
      templateId: this.templateIdFormControl.value!,
      gameId: this.gameIdFormControl.value!,
      event: this.eventControl.value!,
      email: this.emailControl.value!,
      pageNumber: pageNumberP,
      pageSize: 15,
      fromDate: new Date(this.fromDateFormControl.value!),
      toDate: new Date(this.toDateFormControl.value!),
    }).subscribe({
      next: () => {
        this.updatePaginator()
      },
      error: err => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  onBtFirst() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(1);
  }

  onBtLast() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pagedEventResponse?.TotalPages!);
  }

  onBtNext() {
    if ((this.pageNumber + 1) > this.totalPages) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber + 1);
  }

  onBtPrevious() {
    if (this.pageNumber === 1) {
      return;
    }

    this.getPaginatedResponse(this.pageNumber - 1);
  }

  updatePaginator() {
    this.pageNumber = this.pagedEventResponse!.PageNumber;
    this.totalPages = this.pagedEventResponse!.TotalPages;
    this.totalRecords = this.pagedEventResponse!.TotalRecords;
    let toNumber = this.pageNumber * this.pagedEventResponse!.PageSize;
    if (toNumber > this.totalRecords) {
      this.toNumber = this.totalRecords;
      this.fromNumber = (toNumber - this.pagedEventResponse!.PageSize) + 1;
    } else {
      this.toNumber = toNumber;
      this.fromNumber = (this.toNumber - this.pagedEventResponse!.PageSize) + 1;
    }
  }
}
