import {Injectable} from "@angular/core";
import {ColDef} from "ag-grid-community";
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class SendGridTable {

  constructor(private datePipe: DatePipe) {
  }

  public sendGridTableDefs: ColDef[] = [
    {
      headerName: "Id",
      field: "Id",
      sortable: true,
      resizable: true
    },
    {
      headerName: "Event",
      field: "Event",
      sortable: true,
      resizable: true
    },
    {
      headerName: "Player Id",
      field: "PlayerId",
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        return params.value == "00000000-0000-0000-0000-000000000000" ? "N/A" : params.value
      }
    },
    {
      headerName: "Email",
      field: "Email",
      resizable: true,
      sortable: true
    },
    {
      headerName: "Sendgrid Template",
      field: "SendGridTemplateId",
      resizable: true,
      sortable: true
    },
    {
      headerName: "Created On",
      field: "CreatedOn",
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y');
        return convertedDate ? convertedDate : '';
      }
    }
  ];
}
