<app-dialog-base [showLoadingSpinner]="uiState === uiStateForTemplate.ShowLoading">
  <span dialog-title>SendGrid Event Data</span>

  <ng-container dialog-content>
    <div>
      <div class="margin-top-med">
        <p class="bold-text">Event Type</p>
        <p>{{event.Event}}</p>
      </div>

      <div class="margin-top-med">
        <p class="bold-text">Email</p>
        <p>{{event.Email}}</p>
      </div>

      <div class="margin-top-med">
        <p class="bold-text">Metadata</p>
        <pre><app-formatter [data]="metadata"></app-formatter></pre>
      </div>
    </div>
  </ng-container>
  <ng-container actions></ng-container>
</app-dialog-base>
